import {globalConfig} from 'Config/config';
import {store} from 'Redux/PersistStore';
import {v4 as uuidv4} from 'uuid';
import {Queries} from '../../GQL/GQLConstants';
import {GQLType, gqlCall} from '../../GQL/utils';

export const getMemberCashbackEligibility = async (memberDetails, headers) => {
  let request = {
    memberId: memberDetails?.currentMemberDetails?.memberId,
    membershipId: memberDetails?.membershipId,
    channel: '',
  };
  try {
    // For non membership clients -> cashback eligibility call is not required.
    if (!request?.membershipId) {
      return;
    }
    let resp = await gqlCall(
      GQLType.QUERY,
      Queries.GET_MEMBER_CASHBACK_ELIGIBILITY,
      request,
      headers,
      globalConfig._config.BFF.estBFF,
      false,
    );
    let {cashbackEligibility} = resp?.data;
    return cashbackEligibility;
  } catch (error: any) {
    console.log(error);
  }
};

export const getMemberRemaingCashback = async (memberDetails) => {
  const state: any = store.getState();
  let header = {
    'tenant-id': state?.login.tenantID,
    'x-correlation-id': uuidv4(),
  };
  let request = {
    memberId: memberDetails?.currentMemberDetails?.memberId,
    membershipId: memberDetails?.membershipId,
    channel: '',
  };
  try {
    let resp = await gqlCall(
      GQLType.QUERY,
      Queries.GET_MEMBER_REMAING_CASHBACK,
      request,
      header,
      globalConfig._config.BFF.estBFF,
      false,
    );
    let {cashbackEligibility} = resp?.data;
    return cashbackEligibility;
  } catch (error: any) {
    console.log(error);
  }
};
export const getSolicitationId = (memberDetails) => {
  let solicitationId;
  let tableData = memberDetails?.subscriptions
    ? memberDetails?.subscriptions?.length > 0
      ? memberDetails?.subscriptions?.filter((subData) => {
          return subData?.memberId === memberDetails?.currentMemberDetails?.memberId;
        })
      : []
    : null;
  (tableData || []).forEach((item) => {
    if (item.code.toUpperCase() === 'ACTIVE') {
      solicitationId = parseInt(item.solicitationId);
    }
  });
  if (solicitationId) {
    return solicitationId;
  } else {
    let currentSubscription = getCurrentSubscription(memberDetails);
    return parseInt(currentSubscription?.solicitationId);
  }
};

export const getCurrentSubscription = (memberData) => {
  let tableData = memberData?.subscriptions
    ? memberData?.subscriptions?.length > 0
      ? memberData?.subscriptions?.filter((subData) => {
          return subData?.memberId === memberData?.currentMemberDetails?.memberId;
        })
      : []
    : null;
  let subscriptions = tableData?.slice();
  try {
    if (Boolean(subscriptions) === false || subscriptions.length === 0) {
      return null;
    }
    subscriptions.sort(function (a, b) {
      var a_startDate = new Date(a.startDate);
      var b_startDate = new Date(b.startDate);
      if (a_startDate.getTime() === b_startDate.getTime()) {
        return 0;
      }
      return a_startDate > b_startDate ? -1 : 1;
    });
    return subscriptions[0];
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const checkDateOrReturnNull = (date) => {
  return date ? date : null;
};
