import {ulsterRIPackages} from 'Utils/constants/genericConstants';

export const updateFormContent = (formContent, targetFormFields) => {
  let tempFormContent = Object.assign(formContent, {});
  if (tempFormContent && tempFormContent['fields']) {
    tempFormContent.fields.forEach((field) => {
      if (targetFormFields[field.name]) {
        field[targetFormFields[field.name].targetAttribute] =
          targetFormFields[field.name].targetValue;
        field[targetFormFields[field.name].targetAttribute1] =
          targetFormFields[field.name].targetValue1;
      }
    });
  }

  return tempFormContent;
};

export const getCurrency = (selectedMemberData) => {
  if (ulsterRIPackages.data.includes(selectedMemberData?.packageId)) {
    return 'EUR';
  } else {
    return 'GBP';
  }
};

export const stringAscSort = (a, b) => {
  return a.localeCompare(b);
};

export const trimFormValues = (formValues) => {
  Object.keys(formValues).forEach((formKey) => {
    if (formValues[formKey]) {
      formValues[formKey] = formValues[formKey].toString().trim();
    }
  });
  return formValues;
};

export const filterServiceRequestTypeDTOS = (data) => {
  const filteredServiceTypes = data.serviceTypes
    .map((serviceType) => {
      const filteredDTOS = serviceType?.serviceRequestTypeDTOS?.filter(
        (dto) =>
          dto.name.toLowerCase().includes('comment') || dto.name.toLowerCase().includes('query'),
      );
      return {
        ...serviceType,
        serviceRequestTypeDTOS: filteredDTOS,
      };
    })
    .filter((serviceType) => serviceType.serviceRequestTypeDTOS.length > 0);

  return {
    ...data,
    serviceTypes: filteredServiceTypes,
  };
};
