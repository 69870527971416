import {LoadingButton} from '@mui/lab';
import {Box, Divider, IconButton, Paper, Stack, Typography} from '@mui/material';
import {CustomTextInput, NoSearchResults, SnackBar, Wrapper} from 'Components';
import {useCSContext} from 'Context/ContentStackContext';
import {useSnackBar} from 'Hooks';
import {MEMBER_SEARCH} from 'Pages/SearchMember/searchConstants';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';
import {getTenantServiceMapping} from 'Redux/Slices/TenantServicesMappings';
import {getAllMetadata} from 'Redux/Slices/allMetadata';
import {setTenantDetails, updateTenantID} from 'Redux/Slices/login';
import {fetchMemberDetails, setCurrentMemberBenefitDetails} from 'Redux/Slices/selectedMember';
import {searchSRByID} from 'Redux/Slices/selectedSR';
import iconSize from 'Theme/Icon';
import globalSpacing from 'Theme/Spacing';
import {DASHBOARD} from 'Utils/constants/Paths';
import {SEARCH_NO_RESULTS_ERROR} from 'Utils/constants/genericConstants';
import {TabPanelType} from 'Utils/enums';
import {getErrorMsg} from 'Utils/helpers/ErrorHandler';
import {trimFormValues} from 'Utils/helpers/SRDetailViewHelper';
import {resetReduxOnSearch} from 'Utils/helpers/searchHelper';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import styles from './styles';

const SRSearch = () => {
  const {artifacts} = useCSContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const [noResults, setNoResults] = useState(false);
  const clientArray = useSelector((state: any) => state?.login?.loginInfo?.configs);
  const client = clientArray.map((item) => item['name']);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);

  const validationSchema = Yup.object({
    client: Yup.string().required(),
    externalId: Yup.string().required(),
  });

  const formik: any = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    initialValues: {
      externalId: '',
      client: client?.[0],
    },
    onSubmit: async (formValues: any) => {
      let values = trimFormValues(formValues);
      noResults && setNoResults(false);
      let payload = {
        id: values.externalId,
      };
      let response = await dispatch(searchSRByID(payload)).unwrap();
      if (response?.error) {
        setNoResults(true);
        setError(getErrorMsg(response.error));
      } else if (
        response &&
        response?.data?.getServiceRequestById &&
        response?.data?.getServiceRequestById?.id
      ) {
        const variable = {
          memberId: response.data.getServiceRequestById.member?.id,
          membershipId:
            response.data.getServiceRequestById.member?.membershipId ??
            response.data.getServiceRequestById.member?.id,
        };
        if (response.data.getServiceRequestById.member?.tenantId) {
          await dispatch(updateTenantID(response.data.getServiceRequestById.member?.tenantId));
        }
        const memberResponse = await dispatch(fetchMemberDetails({variable})).unwrap();
        if (memberResponse?.error) {
          setError(getErrorMsg(response.error));
        } else if (memberResponse) {
          dispatch(setCurrentMemberBenefitDetails(tenantConfig?.additionalBenefits || '[]'));
          resetReduxOnSearch(dispatch);
          dispatch(
            isUpdateSRList({
              isRefreshSRList: true,
              filterType: 'Recent SRs',
            }),
          );
          dispatch(getTenantServiceMapping({packageId: memberResponse.packageId}));
          dispatch(getAllMetadata({}));
          navigate(DASHBOARD, {state: {tab: TabPanelType.serviceRequests, isSRDialogView: true}});
        } else {
          setError(SEARCH_NO_RESULTS_ERROR);
        }
      } else {
        setError(SEARCH_NO_RESULTS_ERROR);
      }
    },
  });

  useEffect(() => {
    dispatch(setTenantDetails(client[0]));
  }, []);

  const clearMessageOnClose = () => {
    setError('');
  };

  const resetTenantDetails = async () => {
    await dispatch(setTenantDetails(client[0]));
  };

  return (
    <Wrapper>
      <Paper sx={styles.paper}>
        <Stack spacing={globalSpacing.s} sx={{padding: globalSpacing.m}}>
          <Typography variant="h3"> SR Search</Typography>
          {/* Commented thid Code as we upgraded the form!!! */}
          {/* <Typography variant="h6"> {data.member_search_text} </Typography> */}
          <Divider light />
          <Box sx={styles.rowContainer}>
            <CustomTextInput
              label="Client"
              name="client"
              inline
              queryTypesMenu={client}
              onChange={(e) => {
                dispatch(setTenantDetails(e.target.value));
                formik.handleChange(e);
              }}
              value={formik.values['client']}
            />
            <CustomTextInput
              label="SR #"
              inline
              name="externalId"
              onChange={formik.handleChange}
              value={formik.values['externalId']}
            />
          </Box>

          <Box sx={styles.rowContainer}>
            <LoadingButton
              disabled={formik.values['externalId'] && formik.values['client'] ? false : true}
              onClick={(e) => formik.handleSubmit(e)}
              variant="contained"
              sx={{width: '25%'}}
              //   loading={loading}
            >
              Search
            </LoadingButton>
            <IconButton
              color="primary"
              aria-label="Reset Form"
              onClick={(e) => {
                formik.resetForm({});
                resetTenantDetails();
                noResults && setNoResults(false);
              }}
              sx={{paddingY: '0rem', marginLeft: globalSpacing.xs}}>
              <img src={artifacts.refresh} style={iconSize} alt="Reset Form" />
            </IconButton>
          </Box>
        </Stack>
      </Paper>
      {noResults && <NoSearchResults CSTexts={MEMBER_SEARCH.no_search_result} />}
      {error && (
        // eslint-disable-next-line react/jsx-no-undef
        <SnackBar
          errorMessage={error}
          isSnackBarOpen={isSnackBarOpen}
          closeSnackBar={closeSnackBar}
          clearMessageOnClose={clearMessageOnClose}
        />
      )}
    </Wrapper>
  );
};

export default SRSearch;
